<template>
  <div class="my-competition">
    <router-view />
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.my-competition {
  height: 100%;
}
</style>